// Composables
import { createRouter, createWebHashHistory } from 'vue-router';
import useAppStore from '@/store/app';
import Cookies from 'js-cookie';

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/default/Default.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('@/views/destinations/Destinations.vue'),
      },
      {
        path: '/destinations/new',
        name: 'NewDestination',
        component: () => import('@/views/destinations/AddDestination.vue'),
      },
      {
        path: '/destinations/:destinationUuid/edit',
        name: 'EditDestination',
        component: () => import('@/views/destinations/EditDestination.vue'),
      },
      {
        path: '/destinations/:destinationUuid/parties',
        name: 'PartiesList',
        component: () => import('@/views/PartiesList.vue'),
      },
      {
        path: '/destinations/:destinationUuid/parties-create-editor',
        name: 'PartiesCreateEditor',
        component: () => import('@/views/PartiesCreateEditor.vue'),
      },
      {
        path: '/destinations/:destinationUuid/parties/:partyId/manager',
        name: 'partyManager',
        component: () => import('@/views/PartyManager.vue'),
      },
      {
        path: 'destinations/:destinationUuid/themes',
        name: 'SimpleQuestionsThemesList',
        component: () => import('@/views/SimpleQuestions/ThemesList.vue'),
      },
      {
        path: 'destinations/:destinationUuid/luckycards',
        name: 'LuckyCardsList',
        component: () => import('@/views/citypoly/LuckyCardsList.vue'),
      },
      {
        path: 'destinations/:destinationUuid/themes/:themeUuid/edit',
        name: 'SimpleQuestionsEditTheme',
        component: () => import('@/views/SimpleQuestions/ThemeEditor.vue'),
      },
      {
        path: '/destinations/:destinationUuid/themes/new',
        name: 'SimpleQuestionsNewTheme',
        component: () => import('@/views/SimpleQuestions/ThemeEditor.vue'),
      },
      {
        path: '/destinations/:destinationUuid/pois',
        name: 'POIsList',
        component: () => import('@/views/pois/PoiList.vue'),
      },
      {
        path: '/destinations/:destinationUuid/pois/edit/:poiUuid?',
        name: 'PoiEditor',
        component: () => import('@/views/pois/PoiEditor.vue'),
      },
      {
        path: '/destinations/:destinationUuid/categories',
        name: 'CategoriesList',
        component: () => import('@/views/categories/CategoriesList.vue'),
      },
      {
        path: '/destinations/:destinationUuid/categories/new',
        name: 'NewCategory',
        component: () => import('@/views/categories/CategoryEditor.vue'),
      },
      {
        path: '/destinations/:destinationUuid/categories/edit/:categoryId',
        name: 'EditCategory',
        component: () => import('@/views/categories/CategoryEditor.vue'),
      },
      {
        path: '/organizations',
        name: 'OrganizationsList',
        component: () => import('@/views/organizations/OrganizationsList.vue'),
      },
      {
        path: '/organizations/new',
        name: 'AddOrganization',
        component: () => import('@/views/organizations/AddOrganization.vue'),
      },
      {
        path: '/my/organization',
        name: 'MyOrganization',
        component: () =>
          import('@/views/organizations/OrganizationDetails.vue'),
      },
      {
        path: '/organizations/details/:orgId',
        name: 'OrganizationDetails',
        component: () =>
          import('@/views/organizations/OrganizationDetails.vue'),
      },
      {
        path: '/organizations/details/:orgId/admins',
        name: 'OrganizationAdminsAdd',
        component: () => import('@/views/organizations/AddAdmin.vue'),
      },
      {
        path: '/organizations/details/:orgId/edit',
        name: 'OrganizationEdit',
        component: () => import('@/views/organizations/EditCoordinates.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/base/Login.vue'),
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach(async (to) => {
  const store = useAppStore();

  // If there is no currentEditor but the cookie is still present, then the currentEditor is generated.
  if (!store.currentEditor && Cookies.get('currentEditor')) {
    store.setCurrentEditor();
  }

  // If the current destination is not defined, we redirect to the home page.
  const routesWithoutDestinationId = [
    'Login',
    'Home',
    'OrganizationAdminsAdd',
    'OrganizationDetails',
    'OrganizationsList',
    'AddOrganization',
    'MyOrganization',
    'OrganizationEdit',
    'NewDestination',
  ];

  if ((!routesWithoutDestinationId.includes(to.name))
    && !store.currentDestination.id) {
    return { name: 'Home' };
  }

  // Once the currentEditor has been verified, we check that he is authenticated.
  // if it is not, the application is cleaned up and redirected to the login page.
  if (
    (!store.authenticated || !Cookies.get('currentEditor')) &&
    to.name !== 'Login'
  ) {
    return { name: 'Login' };
  }

  return null;
});

export default router;
